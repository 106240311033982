<template>
    <v-row>
        <v-col>
            <v-card>
                <v-card-title class="text-center justify-center py-6 custom-card-title">{{ title }}</v-card-title>
                <v-card-text>
                    <v-tabs
                        v-model="tab"
                        centered
                        dark
                        icons-and-text
                        ref="tabsItems"
                        >
                        <v-tabs-slider></v-tabs-slider>

                        <v-tab>
                            Round Robin
                            <v-icon>mdi-table-large</v-icon>
                        </v-tab>

                        <v-tab>
                            Final
                            <v-icon>mdi-tournament</v-icon>
                        </v-tab>

                        </v-tabs>

                        <v-tabs-items v-model="tab" touchless>
                        <v-tab-item :key="0">
                        <div>
                            <v-row>
                                <v-col>
                                    <v-data-table
                                        :headers="activeHeaders"
                                        :sort-by="['position']"
                                        :items="filteredResults"
                                        hide-default-footer
                                        class="elevation-1"
                                        mobile-breakpoint="0">

                                        <template v-slot:item.position="{item}">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{  item.position == 0 ? '-' : item.position }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.points="{item}">
                                            <h6 class="ma-0 font-weight-medium">
                                                {{ item.points }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceFor="{item}">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor ? item.pointsDifferenceFor : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDifferenceAgainst="{item}">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceAgainst ? item.pointsDifferenceAgainst : '-' }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.pointsDiff="{item}">
                                            <h6 class="ma-0">
                                                {{ item.pointsDifferenceFor - item.pointsDifferenceAgainst }}
                                            </h6>
                                        </template>

                                        <template v-slot:item.teamEnt.name="{item}">
                                            <div style="display: flex;" class="cursor-pointer" @click="goToProfile(item.teamEnt.id)">
                                                <div>
                                                    <v-img
                                                        :src="`/assets/gaa/teams/icons/${item.teamEnt.code}.WEBP`"
                                                        contain
                                                        max-width="30"
                                                        class="mr-2 img_border"
                                                    ></v-img>
                                                    </div>
                                                <div>{{ item.teamEnt.name }}</div>
                                            </div>
                                        </template>

                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </div>
                        </v-tab-item>
                        <v-tab-item
                            :key="2"
                        >
                        <v-container>
                            <base-card>
                                <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'pa-1' : ''">
                                    <v-data-table
                                    :headers="knockoutHeaders"
                                    :items="[final]"
                                    hide-default-footer
                                    hide-default-header
                                    class="elevation-1"
                                    mobile-breakpoint="0"
                                    >

                                    <template v-slot:item.homeLogo="{item}">
                                        <v-img
                                        :src="`/assets/gaa/teams/icons/${item.homeLogo}.WEBP`"
                                        :alt="item.homeLogo"
                                        contain
                                        max-width="30"
                                        class="img_border"
                                        ></v-img>
                                    </template>
                                    <template v-slot:item.home="{item}">
                                        <h6 class="ma-0 font-weight-medium">
                                        {{ item.homeTeam }}
                                        </h6>
                                    </template>

                                    <template v-slot:item.date="{item}">
                                        {{  $vuetify.breakpoint.xsOnly ? item.matchResult ? item.homePoints + ':' + item.awayPoints : formatDateUS(item.date) :  formatDateUS(item.date) }}
                                    </template>

                                    <template v-slot:item.time="{item}">
                                        {{ item.matchResult ? item.homeGoals + ':' + item.homePoints + ' - ' + item.awayGoals + ':' + item.awayPoints : item.time }}
                                    </template>

                                    <template v-slot:item.venue="{item}">
                                        {{ $vuetify.breakpoint.xsOnly ? item.venue.substring(item.venue.indexOf(',') + 1) : item.venue }}
                                    </template>

                                    <template v-slot:item.away="{item}">
                                        <h6 class="ma-0 font-weight-medium">
                                        {{ item.awayTeam }}
                                        </h6>
                                    </template>
                                    <template v-slot:item.awayLogo="{item}">
                                        <v-img
                                        :src="`/assets/gaa/teams/icons/${item.awayLogo}.WEBP`"
                                        :alt="item.awayLogo"
                                        contain
                                        max-width="30"
                                        class="img_border"
                                        ></v-img>
                                    </template>
                                    </v-data-table>
                                </v-card-text>
                            </base-card>
                        </div>
                        </v-container>
                        </v-tab-item>
                    </v-tabs-items>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>
<script>

import { mapActions, mapGetters } from 'vuex'
export default {
    props: ['stage'],
    data(){
        return {
            tab: 0,
            roundRobinHeaders: [
                {text: 'Position', value: 'position', sortable: true},
                {text: 'Team', value: 'teamEnt.name', sortable: true},
                {text: 'Win', value: 'win', sortable: true},
                {text: 'Loss', value: 'loss', sortable: true},
                {text: 'Draw', value: 'draw', sortable: true},
                {text: 'For', value: 'pointsDifferenceFor', sortable: true},
                {text: 'Against', value: 'pointsDifferenceAgainst', sortable: true},
                {text: '+/-', value: 'pointsDiff', sortable: true},
                {text: 'Points', value: 'points', sortable: true},
            ],
            knockoutHeaders: [
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'homeLogo',
                },
                { text: 'Home', value: 'home' },
                { text: '', value: 'time' },

                { text: 'Away', value: 'away' },
                {
                    text: '',
                    align: 'start',
                    sortable: false,
                    value: 'awayLogo',
                },
                { text: 'Date', value: 'date' },
            ],
            teams: [],
        }
    },
    computed: {
        ...mapGetters(["loggedInUser", "getFixtures", "getMunsterRoundRobin", "getLeinsterRoundRobin", "getJoeMcDonaghRoundRobin"]),
        title(){
            let tournment = this.stage.charAt(0).toUpperCase() + this.stage.slice(1);
            if(tournment == 'Munster' || tournment == 'Leinster' || tournment == 'Ulster' || tournment == 'Connacht' || tournment == 'All Ireland'){
                let tournament = this.$router.currentRoute.meta.sport.charAt(0).toUpperCase() + this.$router.currentRoute.meta.sport.slice(1);
                return tournment + ` Senior ${tournament} Championship`;
            } else {
                return tournment + " Cup";
            }
        },
        competitionRounds(){
            let competitionRounds = [...this.getFixtures.reduce((set, fixture) => set.add(fixture.round), new Set())];
            return competitionRounds.filter(it => (it !== "Round Robin" && it != null));
        },
        final(){
            let fixtures = [...this.getFixtures];
            if(this.stage.toLowerCase() == 'munster'){
                return fixtures.find(it => it.matchdayLabel == 'Munster Final');
            } else {
                return fixtures.find(it => it.matchdayLabel == 'Leinster Final');
            }
        },
        filteredResults(){
            // TODO - this is poor, getting too much data
            if(this.stage.toLowerCase() == 'munster'){
                return this.getMunsterRoundRobin;
            } else if(this.stage == 'leinster'){
                return this.getLeinsterRoundRobin;
            } else if(this.stage == 'Joe McDonagh'){
                return this.getJoeMcDonaghRoundRobin
            }
        },
        activeHeaders(){
            if(this.$vuetify.breakpoint.xsOnly){
                return [
                    {text: 'Team', value: 'teamEnt.name', sortable: true},
                    {text: 'Points', value: 'points', sortable: true},
                    {text: '+/-', value: 'pointsDiff', sortable: true},
                    {text: 'Win', value: 'win', sortable: true},
                    {text: 'Loss', value: 'loss', sortable: true},
                    {text: 'Draw', value: 'draw', sortable: true},
                ]
            } else {
                return this.roundRobinHeaders;
            }
        }
    },
    methods:{
        ...mapActions(["fetchFixtures", "fetchCompetitionStandings"]),
        goToProfile(id){
            this.$router.push(`/app/${this.$router.currentRoute.meta.sport}/statistics/profile/${id}`)
        },
        formatDate(date) {
            return date.split('-').reverse().map((part, index) => index === 2 ? part.slice(-2) : part).join('/');
        },
        formatDateUS(date){
            return date.replace(/(\d+)\/(\d+)\/(\d{2})(\d{2})/, (_, m, d, __, y) => `${d.padStart(2, '0')}/${m.padStart(2, '0')}/${y}`);
        },
    },
    mounted(){
        this.fetchFixtures(this.$store.getters.getCompetitionId);
        this.fetchCompetitionStandings(this.$store.getters.getCompetitionId);
        // const tabsItems = this.$refs.tabsItems.$el;

        // // Prevent swipe to change tabs
        // tabsItems.addEventListener('touchstart', this.preventTouch, { passive: false });
        // tabsItems.addEventListener('touchmove', this.preventTouch, { passive: false });
        // tabsItems.addEventListener('touchend', this.preventTouch, { passive: false });
    }
}
</script>

<style lang="scss" scoped>
    ::v-deep .v-data-table-header th .v-icon {
        display: none;
    }

    /* Ensure the headers still look clickable */
    .v-data-table-header th {
        cursor: pointer;
    }
    .img_border {
        border-style: solid;
        border-width: 2px;
    }

    .custom-card-title {
        word-break: keep-all !important;
        white-space: normal !important;
    }

</style>